
$primary: #0772B9;
$body-color: #4f4f4f;
$light: #ddd;

:root {
  --muted: #C9C9C9;
  --border-muted: #eee
}

@import "node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@700&family=Roboto:wght@900&display=swap');

// removes the grab cursor on the park canvas
.a-grab-cursor { cursor: default !important; }
// but still allows the cursor to change to pointer when hovering
.a-grab-cursor.a-mouse-cursor-hover {
  cursor: pointer !important;
}

body {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}

h1, h2, h3, h4, h5, h6, .btn {
  font-family: 'Roboto', sans-serif;
}

.btn {
  border-radius: 0px;
  font-weight: 500;
}

// dat.gui debug
.dg.ac {
  z-index: 1;
}

.red-text {
  color: red;
}

.z10 {
  z-index: 10;
  position: relative;
}

.invisible {
  display: none;
}

.z-index-1 {
  position: relative;
  z-index: 1;
}

.rs-base {
  z-index: 1000;
  top: 100px;
  left: 0;
  width: 25%;
  height: 70%;
}

.rs-counter-id {
  width: 150px;
}

.Toastify__toast--info {
  background: #0772B9 !important;
}
.Toastify__close-button {
  align-self: flex-end !important;
}





html, body {
  height: 100%;
}


.no_pointer {
  pointer-events: none !important;
}
.pointer_all {
  pointer-events: all !important;
}



.a-canvas {
  z-index: 1;
}



.cssCanvas {
  position: relative;
  z-index: 1;
}

.fullscreenSize {
  width: 1200px; 
  height: 600px;
  position: absolute;
  top: 0;
  left: 0;
}

#browser_bar {
  background: whitesmoke;
  width: 41%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 11;
  box-shadow: -2px -1px 12px 3px #333;

  &.lighter-box-shadow {
    box-shadow: -2px -1px 12px 3px #888 !important;
  }
}

#nft_fetcher_submit {
  width: 20%;
  padding: 5px 0px;
  background: white;
  border: none;
  outline: none;
}

#nft_address_input {
  width: 80%;
  padding: 5px 14px;
  border: none;
  border-left: 1px solid black;
  outline: none;
}

.zOnTop {
  z-index: 10;
}

#intro_ui {
  background-color: lightblue;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

#hud {

  .nft-iterate-btns {
    position: absolute;
    bottom: 0px;
    z-index: 1;
    border: 1px solid white;
    padding: 10px 15px;
    opacity: 0.8;
    background: white;
    color: rgb(32, 129, 226);
    font-weight: 900;

    &:hover {
      opacity: 1;
    }
  }

  #next-nft-btn {
    right: 0;
    border-right: 0;
    border-radius: 10px 0px 0px 0px;
  }
  #prev-nft-btn {
    left: 0;
    border-left: 0;
    border-radius: 0px 10px 0px 0px;
  }

	
}

#mobilePortraitModal {

	.modal-body {
		text-align: center;
	}
}

.ReactRoot {

  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  
  @media screen and (orientation: portrait) {
    width: 100%;
    height: 100%;
  }

  #hud {
    width: 100%;
    height: 100%;
  }

  .loading-screen {

    background: white;
    z-index: 100;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    opacity: 1;
    transition: opacity 0.5s linear;
    height: 100%;
    width: 100%;

    &.dissolve {
      opacity: 0.0;
    }

    .container-fluid {
      height: 100%;
      width: 100%;
    }
  
    #loading-text {
      margin: 14px;
      color: black;
      font-size: 28px;
      text-align: center;
      font-weight: bold;
    }
  
    #loading-bar {
      margin-top: 10%;
      margin: 0 auto;
      @media screen and (min-width: 991.98px) {
        margin: 0 auto;
      }
    }

  }

}

